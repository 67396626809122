import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { globalHistory } from "@reach/router"
import { graphql , Link as GatsbyLink  } from 'gatsby'
import { connect } from 'react-redux';

import MapIcon from '../images/overlay/overlay-icon-map.svg'
// import SEO from '../components/SEO/SEO'
// import ArticleContainer from '../containers/ArticleContainer'
import { initParticipantCategory, setInitalizedToTrue, setIsMapView, setIsCategoryView, updateRoute, setActiveParticipant, activateParticipant, setCurrentParticipants, setActivatedParticipantOffsetX,filterParticipants, setParticipantData } from '../state/actions'

const PageTemplate = (props) => {
  const { data, pageContext, isStateInitialized, 
    isMapView, isCategoryView, activated_participant_id,
    initParticipantCategory, setInitalizedToTrue,
    setIsMapView, setIsCategoryView, updateRoute, setActiveParticipant, activateParticipant, participants, setCurrentParticipants, setActivatedParticipantOffsetX, lastCategoryRoute, activeCategoryId, activeCategorySlug, filterParticipants, setParticipantData } = props;
  // console.log('\n\n\n>>>>>>participantCategoryPage pageContext: ', pageContext );

  const {
    id,
    databaseId,
    title,
    slug,
    uri,
    participantCategories,
    mi_participant
  } = data.wpParticipant

  const newParticipant = {...mi_participant, title, id: databaseId}

  // const {
  //   databaseId: categoryId,
  //   name: categoryName,
  //   uri: categorySlug
  // } = data.wpParticipant.participantCategories.nodes[0]
  
  const {
    allWpParticipant: { nodes },
  } = data

  const ps = nodes.map(p => ({
    id: p.databaseId,
    categoryId: p.participantCategories.nodes[0].databaseId
  }))



  // useEffect(() => {
  //   console.log('>>> mounted')
  //   if(participants.length === 0 ){
  //     console.log('participants.length === 0 ')
  //     // console.log('ps: ', ps);
  //     // setCurrentParticipants(ps)
  //     // setActivatedParticipantOffsetX(24)
  //     // setActiveParticipant(firstParticipantId)
  //     // activateParticipant(firstParticipantId)
  //     // updateRoute(globalHistory.location)
  //   }
  // }, []);


  const categoryDatabaseId = participantCategories.nodes[0].databaseId
  const categoryName = participantCategories.nodes[0].name
  const categoryUri = participantCategories.nodes[0].uri
  const {
    miParticipantSubheadline,
    miParticipantPhone,
    miParticipantOpeningTimes,
    miParticipantOpeningTimesTextarea,
    miParticipantContent,
    miParticipantPrivateWebsite,
    miParticipantFacebook,
    miParticipantInstagram,
    miParticipantTwitter,
    miParticipantGeodata,
    miParticipantHeroSlider,
    miPictureCredits,

  } = mi_participant

  const {
    city,
    latitude,
    longitude,
    placeId,
    postCode,
    streetAddress,
    streetName,
    streetNumber
  } = miParticipantGeodata



  const mounted = useRef();
  
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
      console.log('\n\n\n participatnPage mounted filterPartiicpants')
      console.log('participantCategories.nodes[0]', participantCategories.nodes[0])
      setIsCategoryView(false);
      if(mi_participant){
        setParticipantData(newParticipant)
      }
      if(!lastCategoryRoute){
        filterParticipants(categoryDatabaseId, categoryName, categoryUri)
      }
      if(participants.length === 0 ){
        setCurrentParticipants(ps)
      }
      if(isMapView && !isCategoryView && !activated_participant_id) {
        console.log('isMapView && !isCategoryView && !activated_participant_id')
        // setActivatedParticipantOffsetX(e.currentTarget.offsetLeft);
        setActiveParticipant(databaseId)
        activateParticipant(databaseId)
      } else if(activated_participant_id) {
        const el = document.getElementById(`mi_card_mobile_${databaseId}`);
        if(el){
          const offsetX = el.offsetLeft;
          console.log('offsetX: ', offsetX);
          setActivatedParticipantOffsetX(offsetX)
        }



      }
    

    } else {
      if(mi_participant){
        setParticipantData(newParticipant)
      }
      // do componentDidUpdate logic
      console.log('isMapView: ', isMapView, 'isCategoryView: ', isCategoryView, ', activated_participant_id: ', activated_participant_id)
      if(!lastCategoryRoute){
        filterParticipants(categoryDatabaseId, categoryName, categoryUri)
      }
      if(isMapView && !isCategoryView && !activated_participant_id) {
        console.log('isMapView && !isCategoryView && !activated_participant_id')
        // setActivatedParticipantOffsetX(e.currentTarget.offsetLeft);
        setActiveParticipant(databaseId)
        activateParticipant(databaseId)
      } else if(activated_participant_id) {
        const el = document.getElementById(`mi_card_mobile_${databaseId}`);
        if(el){
          const offsetX = el.offsetLeft;
          console.log('offsetX: ', offsetX);
          setActivatedParticipantOffsetX(offsetX)
        }



      }
    
    }
  });
  

  /*
    if(lastCategoryRoute)
      link to last category route
    else
      if 
  */

  const triggerAction = (cat) => {
    setIsMapView(true)
    setIsCategoryView(true)
    const newLocation = {
      pathname: cat? cat.uri : '/erkunden/'
    }
    updateRoute(newLocation)
    
    if(cat){
      // console.log('participants: ', participants)
      // console.log('cat.databaseId: ', cat.databaseId)
      if (!lastCategoryRoute) {
        filterParticipants(cat.databaseId, cat.name, cat.uri)
      }
      
      const firstOfCatId = participants.find(p => p.categoryId === cat.databaseId).id


      setActiveParticipant(firstOfCatId)
      activateParticipant(firstOfCatId)
      setActivatedParticipantOffsetX(24);
    } else {
      const firstId = participants[0].id
      setActiveParticipant(firstId)
      activateParticipant(firstId)
      setActivatedParticipantOffsetX(24);

      filterParticipants(null)
    }
  }
  // const {
  //   databaseId,
  //   mimeType,
  //   mediaType,
  //   slug,
  //   sourceUrl,
  //   srcSet,
  //   title,
  //   altText
  // } = slider
  if (!isStateInitialized) {

    // const { categoryDatabaseId : id, name } = pageContext;
    // console.log('initStateFromQuery id: ', categoryDatabaseId, ' name: ', categoryName)
    initParticipantCategory(categoryDatabaseId, categoryName)
    setInitalizedToTrue();
    setIsMapView(true);
    setIsCategoryView(true);


    // updateRoute(globalHistory.location)
  }
  return null;
  // return (
  //   <ParticipantContainer>
  //     <MapButtonMobile to={lastCategoryRoute || activeCategorySlug || '/erkunden/'}>
  //       Karte   
  //       <img src={MapIcon} alt="" />
  //     </MapButtonMobile>
      
  //     {title}
  //     {miParticipantContent}
  //   </ParticipantContainer>
  // )
}

const MapButtonMobile = styled(GatsbyLink)`
  display: flex;
  width: 80px;
  height: 40px;


  border: solid 2px #f6efeb;
  background-color: #f6efeb;
  border-radius: 20px;

  font-family: SofiaW01;
  font-size: 11px;
  font-weight: bold;


  line-height: 1.4;


  color: var(--black);
  position: relative;
  align-content: center;
  justify-content: center;
  align-items: center;
  img{
    margin-left: 4px;
  }

  position: fixed;
  top: 99px;
  right: 18px;
`

const ParticipantContainer= styled.div`
  width: 100vw;
  height: calc(100vh - 80px);
  height: calc(var(--vh, 1vh) * 100 - 80px);
  position relative;
  z-index: 8;
  top 0;
  left: 0;
  background: rgba(0,0,255,0.2);
  overflow-y: scroll;

  position: absolute;
  top: 80px;
`;

export const query = graphql`
    query SingleParticipant($databaseId: Int! = 5) {
      allWpParticipant{
        nodes {
          databaseId
          id
          slug
          title
          uri
          participantCategories {
            nodes {
              databaseId
              name
            }
          }
          mi_participant {
            miParticipantShorttitle
            miParticipantHeroSlider {
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 540, maxWidth: 960, quality: 80, cropFocus: CENTER) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
              }
            }
            miParticipantGeodata {
              latitude
              longitude
              streetName
              streetNumber
              placeId
              city
              postCode
              streetAddress
            }
          }
        }
      }
        wpParticipant(databaseId: {eq: $databaseId}) {
          id
          databaseId
          title
          slug
          uri
          participantCategories {
            nodes {
              id
              name
              databaseId
              slug,
              uri
            }
          }
          mi_participant {
            miParticipantShorttitle
            miParticipantContent
            miParticipantFacebook
            miParticipantGeodata {
              city
              latitude
              longitude
              placeId
              postCode
              streetAddress
              streetName
              streetNumber
            }
            miParticipantHeroSlider {
              databaseId
              mimeType
              mediaType
              title
              altText
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 540, maxWidth: 960, quality: 80, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_noBase64
                    }
                }
              }
            }
            miParticipantInstagram
            miParticipantOpeningTimes {
              miParticipantOpeningTimesDay
              miParticipantOpeningTimesTimeframe
            }
            miParticipantOpeningTimesTextarea
            miParticipantPhone
            miParticipantPrivateWebsite
            miParticipantSubheadline
            miParticipantTwitter
            miPictureCredits
          }
        }

    }
`

const mapStateToProps = state => {
  // console.log('mapStateToProps participantCategoryPage: ', state)
  return {
    isStateInitialized: state.isStateInitialized,
    lastCategoryRoute: state.route.href,
    participants: state.participants.participants,
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView,
    activated_participant_id: state.participants.activated_participant_id,
    activeCategoryId: state.participants.active_category.id,
    activeCategorySlug: state.participants.active_category.slug,

  }
};

const mapDispatchToProps = {
  initParticipantCategory,
  setInitalizedToTrue,
  setIsMapView,
  setIsCategoryView,
  updateRoute,
  setActiveParticipant, 
  activateParticipant,
  setCurrentParticipants,
  setActivatedParticipantOffsetX,
  filterParticipants,
  setParticipantData
};

export default connect(mapStateToProps, mapDispatchToProps)(PageTemplate)

